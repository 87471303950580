/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import RegressiveCounter from "../../../../components/RegressiveCounter";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { useNavigate, useParams } from "react-router-dom";
// import OrderService from "../../../../services/order.service";
import { _handleError } from "../../../../helpers/errors";
import { sleep } from "../../../../helpers/functions";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";
import { _pushToastMessage } from "../../../../helpers/messages";
import FantasilandiaService from "../../../../services/fantasilandia.service";
import DoorLockerService from "../../../../services/door_locker.service";
import OrderService from "../../../../services/order.service";
// import DoorLockerService from "../../../../services/door_locker.service";
import In from "../../../../assets/svg/in.svg";
import Out from "../../../../assets/svg/out.svg";

const Confirmation = () => {
  const [intervalStateControl, setIntervalStateControl] = useState(0);
  const { session, loader, order, pickup } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const params = useParams() as { action: string };

  // const Order = new OrderService();

  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    handleAllColorStyles(assets);
  }, [assets]);

  useEffect(() => {
    if (params.action === "delivery") {
      _createNewReservation();
    } else {
      _handlePickupReservation();
    }
  }, []);

  const _handleConfirmOrder = async () => {
    try {
      dispatch(set_loader({ is_loading: true }));

      setIntervalStateControl(intervalStateControl + 1);

      await sleep(500);

      dispatch(set_loader({ is_loading: false }));
      navigate("/");
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _createNewReservation = async () => {
    const { new_order, boxes: selectedBox } = order;

    if (!new_order) {
      _pushToastMessage({
        header: "Error",
        text: "No se pudo crear la reserva",
        type: "error",
      });
      navigate("/");
      return;
    }

    try {
      const reservationResponse: any =
        await new FantasilandiaService().createReservation({
          code: new_order.code,
          terminal_id: new_order.terminal_id,
          pin: new_order.pin || "0000",
          method: new_order.method || "document",
          box: selectedBox.length > 0 ? selectedBox[0] : null,
        });

      const boxes = reservationResponse?.data?.boxes;

      if (!boxes || boxes.length === 0) {
        _handleCancelOrder(reservationResponse?.data?.order?.id);
        _pushToastMessage({
          header: "Error",
          text: "No se pudo crear la reserva",
          type: "error",
        });
        navigate("/");
        return;
      }

      const box = boxes[0];

      const payload: any = {
        com: session?.profile?.door_com_number,
        driveboard: box.desk_number,
        door: box.desk_door_number,
        box: box,
      };
      const openDoorResponse = await new DoorLockerService().openDoor(payload);

      if (openDoorResponse?.data?.status === "error") {
        _handleCancelOrder(reservationResponse?.data?.order?.id);
        _pushToastMessage({
          header: "Error",
          text: "No se pudo abrir la puerta. Vuelve a intentarlo",
          type: "error",
        });
        navigate("/");
        return;
      }
    } catch (e: any) {
      _pushToastMessage({
        header: "Error",
        text: "No se pudo crear la reserva. Vuelve a intentarlo",
        type: "error",
      });
      navigate("/");
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handlePickupReservation = async () => {
    try {
      const { reservation, boxes } = pickup;

      const box = boxes[0];

      const payload: any = {
        com: session?.profile?.door_com_number,
        driveboard: box.desk_number,
        door: box.desk_door_number,
        box: box,
      };

      const openDoorResponse = await new DoorLockerService().openDoor(payload);

      if (openDoorResponse?.data?.status === "error") {
        _pushToastMessage({
          header: "Error",
          text: "No se pudo abrir la puerta. Vuelve a intentarlo",
          type: "error",
        });
        navigate("/");
        return;
      }

      await new FantasilandiaService().finishReservation({
        id: reservation.id,
      });
    } catch (e: any) {
      _pushToastMessage({
        header: "Error",
        text: "No se pudo finalizar la reserva. Vuelve a intentarlo",
        type: "error",
      });
    }
  };

  const _handleCancelOrder = async (order_id: string) => {
    if (!order_id) return;
    try {
      await new OrderService().cancelOrder({ id: order_id });
    } catch (e: any) {
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid h-100">
      <Header />

      <div className="d-flex justify-content-center align-items-center mt-5">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "615px",
            borderRadius: "30px",
            height: "434px",
            padding: "50px",
          }}
        >
          {loader.is_loading && (
            <div className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center text-center bold size-15">
                Aguarde mientras preparamos todo...
              </div>
            </div>
          )}

          {!loader.is_loading && (
            <>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "50%",
                  width: "78px",
                  height: "78px",
                }}
              >
                <img
                  src={params.action === "delivery" ? In : Out}
                  alt="info-action"
                />
              </div>
              <div
                className="d-flex justify-content-center align-items-center text-center bold"
                style={{ fontSize: "32px", width: "420px" }}
              >
                {params.action === "delivery"
                  ? assets.fantasilandia_delivery_with_document_title
                  : assets.fantasilandia_pickup_with_document_title}
              </div>

              <div className="w-100">
                <div className="w-100 text-center mt-4">
                  <button
                    className="px-4 main-button-yellow bold py-1"
                    onClick={_handleConfirmOrder}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">Finalizar</div>
                      <RegressiveCounter
                        clearIntervalAction={intervalStateControl}
                        handleCallback={_handleConfirmOrder}
                      />
                    </div>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
