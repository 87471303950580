/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";
import HomeActionButton from "../../components/HomeActionButton";
import { ReactComponent as Pickup } from "../../../../assets/svg/new_pickup_icon_white.svg";

const SelectPickupMode = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { session } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    handleAllColorStyles(assets);
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    // const backToHome = setTimeout(() => {
    //   navigate("/", { replace: true });
    // }, 30000);

    return () => {
      clearInterval(timeout);
      // clearInterval(backToHome);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="content-section h-100">
        <div
          className="row justify-content-center mt-5"
          style={{ maxHeight: "80vh" }}
        >
          <div
            className="d-flex align-items-center justify-content-center flex-column py-5"
            style={{
              background: "var(--events-default-backdrop-color)",
              width: "80%",
              borderRadius: "30px",
              height: "auto",
              minHeight: "400px",
            }}
          >
            <div className="bold size-15 mb-5 w-100 text-center">
              {assets?.home_text_one || "Selecciona que desea hacer."}
            </div>

            <div className="d-flex justify-content-center mt-5">
              <HomeActionButton
                Image={Pickup}
                title={assets?.events_pickup_partial_title || "Retiro parcial"}
                description={
                  assets?.events_pickup_partial_text ||
                  "Puedes abrir el casillero para retirar o guardar algo y su código sigue válido."
                }
                onClick={() => navigate("/my-box-pickup", { replace: true })}
              />
              <HomeActionButton
                Image={Pickup}
                title={assets?.events_pickup_full_title || "Retiro total"}
                description={
                  assets?.events_pickup_full_text ||
                  "Abre la caja y retira todos tus items. Tu reserva sera finalizada al cerrar la puerta."
                }
                onClick={() => navigate("/open-box-pickup", { replace: true })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPickupMode;
