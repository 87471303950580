interface HomeActionButtonProps {
  Image: any;
  title: string;
  description: string;
  onClick?: () => void;
}
const HomeActionButton = ({
  Image,
  title,
  description,
  onClick,
}: HomeActionButtonProps) => {
  const mainStyles: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "220px",
    height: "auto",
    minHeight: "270px",
    borderRadius: "20px",
    backgroundColor: "var(--events-default-backdrop-color)",
  };

  const imageStyles: any = {
    width: "68px",
    height: "68px",
  };

  return (
    <div className="p-3 mx-3" style={mainStyles} onClick={onClick}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <Image style={imageStyles} />

        <div className="bold size-15 text-center mt-2">{title}</div>
      </div>
      <div
        className="d-flex justify-content-center align-items-center text-center mt-3" style={{flex: "1 1 auto"}}
      >
        {description}
      </div>
    </div>
  );
};

export default HomeActionButton;
