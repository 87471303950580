import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../components/SignIn";
import Home from "../modules/DynamicEvents/pages/Home";
import OpenBoxPickup from "../modules/DynamicEvents/pages/OpenBoxPickup";
import DashboardRouter from "./DashboardRouter";
import OpenBox from "../modules/DynamicEvents/pages/OpenBox";
import Confirmation from "../modules/DynamicEvents/pages/Confirmation";
import HelpUserKeyboard from "../modules/DynamicEvents/pages/HelpUserKeyboard";
import HelpUserSuccess from "../modules/DynamicEvents/pages/HelpUserSuccess";
import HelpUserFailed from "../modules/DynamicEvents/pages/HelpUserFailed";
import MyBox from "../modules/DynamicEvents/pages/MyBox";
import MyBoxPickup from "../modules/DynamicEvents/pages/MyBoxPickup";
import ValidatePinCode from "../modules/DynamicEvents/pages/ValidatePinCode";
import ConfirmationAndFinish from "../modules/DynamicEvents/pages/ConfirmationAndFinish";
import ActiveReservations from "../modules/DynamicEvents/pages/ActiveReservations";
import MaxReservations from "../modules/DynamicEvents/pages/MaxReservations";
import SelectPickupMode from "../modules/DynamicEvents/pages/SelectPickupMode";
// import Boxes from "../modules/DynamicEvents/pages/Boxes";
// import Companies from "../modules/DynamicEvents/pages/Companies";
// import Delivery from "../modules/DynamicEvents/pages/Delivery";
// import OpenBoxDelivery from "../modules/DynamicEvents/pages/OpenBoxDelivery";
// import OpenBoxDeliveryServices from "../modules/DynamicEvents/pages/OpenBoxDeliveryServices";
// import Pickup from "../modules/DynamicEvents/pages/Pickup";
// import Services from "../modules/DynamicEvents/pages/Services";
// import Success from "../modules/DynamicEvents/pages/Success";
// import Apartaments from "../modules/DynamicEvents/pages/Apartaments";
// import ApartmentKeyboard from "../modules/DynamicEvents/pages/ApartmentKeyboard";
// import OpenBoxCustody from "../modules/DynamicEvents/pages/OpenBoxCustody";
// import ConfirmationCustody from "../modules/DynamicEvents/pages/ConfirmationCustody";

export default function DynamicEventsRouter() {
  const { session } = useSelector((state: any) => state);
  useEffect(() => {
    import("../modules/DynamicEvents/styles/_global_events.scss");
  }, []);
  return (
    <BrowserRouter>
      <SignIn open={!session.is_connected || session.sign_in_component} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/select-pickup-mode" element={<SelectPickupMode />} />
        <Route path="/validate-pin" element={<ValidatePinCode />} />

        {/** sac routes */}
        <Route path="/help-user/phone" element={<HelpUserKeyboard />} />
        <Route path="/help-user/phone/success" element={<HelpUserSuccess />} />
        <Route path="/help-user/phone/failed" element={<HelpUserFailed />} />

        {/** open boxes without actions */}
        <Route path="/my-box" element={<MyBox />} />
        <Route path="/my-box-pickup" element={<MyBoxPickup />} />

        {/** delivery boxes */}
        <Route path="/open-box-delivery" element={<OpenBox />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/open-box-and-finish" element={<ConfirmationAndFinish />} />

        {/** pickup boxes */}
        <Route path="/open-box-pickup" element={<OpenBoxPickup />} />

        {/** dashboard routes */}
        <Route path="/dashboard/*" element={<DashboardRouter />} />

        {/** reservations rules */}
        <Route path="/active-reservations-in-others-terminals" element={<ActiveReservations />} />
        <Route path="/max-reservations" element={<MaxReservations />} />

        {/** old routes */}
        {/* <Route path="/delivery" element={<Delivery />} /> */}
        {/* <Route path="/available-boxes" element={<Boxes />} /> */}
        {/* <Route path="/companies" element={<Companies />} /> */}
        {/* <Route path="/open-box-delivery/:apartment" element={<OpenBox />} /> */}
        {/* <Route path="/open-box-custody/:phone" element={<OpenBoxCustody />} /> */}
        {/* <Route
          path="/services/open-box-delivery-services"
          element={<OpenBoxDeliveryServices />}
        /> */}
        {/* <Route path="/success" element={<Success />} /> */}
        {/* <Route path="/pickup" element={<Pickup />} /> */}
        {/* <Route path="/info-apartment" element={<ApartmentKeyboard />} /> */}
        {/* <Route path="/services" element={<Services />} /> */}
        {/* <Route
          path="/delivery-with-apartament/:apartment"
          element={<Apartaments />}
        /> */}
        {/* <Route
          path="/confirmation/custody/:phone"
          element={<ConfirmationCustody />}
        /> */}

        {/* <Route path="/open-box-pickup" element={<OpenBoxPickup />} /> */}
        {/* <Route path="/open-box-delivery" element={<OpenBoxDelivery />} /> */}
        {/* <Route path="/confirmation/:apartment" element={<Confirmation />} /> */}
        {/* <Route path="/info-custody" element={<CustodyKeyboard />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
