/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { _pushToastMessage } from "../../../../helpers/messages";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { ValidateQRCodeDataEventsModule } from "../../../../helpers/validate_qrcode_data";
import { set_session } from "../../../../redux/actions/session";
import EventService from "../../../../services/events.service";
import { set_order } from "../../../../redux/actions/order";
import { set_pickup } from "../../../../redux/actions/pickup";

interface IHiddenQrRreaderProps {
  onChange?: any;
}

const HiddenQrRreader = ({ onChange }: IHiddenQrRreaderProps) => {
  const { session } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const inputRef: any = useRef<any>();
  const debouncedSave = useRef(
    debounce((nextValue) => _formatAndCheckData(nextValue), 700)
  ).current;

  /**
   * Focus input
   * clear interval
   * @returns void
   */
  useEffect(() => {
    const interval: any = setInterval(() => {
      inputRef.current.focus();
    }, 100);

    if (session.sign_in_component) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [session]);

  /**
   * Socket connection
   */
  useEffect(() => {
    const socket: any = io("http://127.0.0.1:3333");
    socket.on("news", (data: any) => {
      console.log(data);
    });

    socket.on("code", (data: any) => {
      const code: any = data.replace(/[\r\n]/gm, "");
      const payload: any = code.toLowerCase();
      _formatAndCheckData(payload);
    });

    socket.on("connect_error", () => console.log("CONECT ERROR"));
    socket.on("reconnect_error", () => console.log("RECONECT ERROR"));

    return () => {
      console.log("Destroy socket connection");
      socket.destroy();
    };
  }, []);

  const _validateCode = async (code: any, target: string = "rut") => {
    try {
      dispatch(set_loader({ is_loading: true }));
      let response = null;

      if (target === "rut") {
        response = await new EventService().searchUser({
          terminal_id: session.profile.id,
          rut: code.toLowerCase(),
          action: "create",
        });
      }

      if (target === "qr_elock") {
        response = await new EventService().searchUserByQR({
          terminal_id: session.profile.id,
          qrcode: code.toLowerCase(),
          action: "create",
        });
      }

      dispatch(set_order({ user: response?.data?.user }));
      dispatch(set_loader({ is_loading: false }));

      /** RULES VALIDATIONS */
      const reservationAtThisTerminal = response?.data?.reservation;
      const setup = response?.data?.setup;

      if (!reservationAtThisTerminal) {
        if (setup?.events_config?.info_enabled) {
          if (
            response?.data?.active_reservations_in_others_terminals?.length > 0
          ) {
            dispatch(
              set_order({
                user: response?.data?.user,
                active_reservations_in_others_terminals: {
                  reservations:
                    response?.data?.active_reservations_in_others_terminals,
                  code: response?.data?.code,
                  message: response?.data?.message,
                  can_create_reservation:
                    response?.data?.can_create_reservation,
                  setup: response?.data?.setup,
                },
              })
            );
            navigate("/active-reservations-in-others-terminals");
            return;
          }
        }

        if (setup?.events_config?.max_reservations_enabled) {
          if (!response?.data?.can_create_reservation) {
            dispatch(
              set_order({
                user: response?.data?.user,
                active_reservations_in_others_terminals: {
                  reservations:
                    response?.data?.active_reservations_in_others_terminals,
                  code: response?.data?.code,
                  message: response?.data?.message,
                  can_create_reservation:
                    response?.data?.can_create_reservation,
                  setup: response?.data?.setup,
                },
              })
            );
            navigate("/max-reservations");
            return;
          }
        }
      }

      if (!response?.data?.reservation) {
        /**
         * check if select box is required
         */
        if (!assets?.events_delivery_boxes_required) {
          /**
           * check if pinpad is required
           */
          if (assets?.events_delivery_pinpad_required) {
            navigate("/validate-pin?redirect_to=open-box-and-finish");
          } else {
            navigate("/open-box-and-finish");
          }
          return;
        }

        /**
         * if select box is required, check if pinpad is required
         */
        if (assets?.events_delivery_pinpad_required) {
          navigate("/validate-pin?redirect_to=open-box-delivery", {
            state: { user: response?.data },
          });
        } else {
          navigate("/open-box-delivery", {
            state: { user: response?.data },
          });
        }
      }

      if (response?.data?.reservation) {
        dispatch(
          set_pickup({
            reservation: response.data?.reservation,
            boxes: response.data?.reservation?.boxes,
          })
        );

        if (assets?.events_pickup_pinpad_required) {
          navigate("/validate-pin?redirect_to=select-pickup-mode");
        } else {
          navigate("/select-pickup-mode");
        }
      }
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        text:
          e?.response?.data?.message ||
          e?.message ||
          "No fue posible encontrar encontrar este usuario. Intenta registrarse usando el codigo QR.",
        type: "error",
        header: "Error",
      });
    }
  };

  /* Proccess data from input */
  const _formatAndCheckData = async (qrcode: any) => {
    inputRef.current.value = qrcode?.toLowerCase();

    dispatch(set_loader({ is_loading: true }));

    const terminal_id = session?.profile?.id;

    const validateCodeResponse: any = await ValidateQRCodeDataEventsModule(
      qrcode,
      terminal_id
    );

    dispatch(set_loader({ is_loading: false }));

    if (!validateCodeResponse.isValid) {
      inputRef.current.value = "";
      _pushToastMessage({
        header: "Error",
        text: validateCodeResponse.message,
        type: "error",
      });
      return;
    }

    switch (validateCodeResponse.method) {
      case "master_key":
        dispatch(set_session({ ...session, ...validateCodeResponse.data }));
        navigate("/dashboard");
        inputRef.current.value = "";
        break;
      case "rut_by_document":
        _validateCode(validateCodeResponse.data.rut);
        inputRef.current.value = "";
        break;
      case "code_qr_elock":
        _validateCode(validateCodeResponse.data.code, "qr_elock");
        inputRef.current.value = "";
        break;
      default:
        break;
    }
  };

  const _handleChangeInput = (e: any) => {
    debouncedSave(e?.target?.value);
  };

  return (
    <div style={{ position: "absolute", top: "-100px" }}>
      <input
        type="text"
        ref={inputRef}
        id="codetext"
        style={{ opacity: 1, color: "#000" }}
        onChange={_handleChangeInput}
      />
    </div>
  );
};

export default HiddenQrRreader;
