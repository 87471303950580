const QrcodeCompany = ({ assets, company }: any) => {
  return (
    <>
      <div
        className="bold mb-2 py-1 px-3 text-center"
        style={{
          background: "#000",
          color: "#FFF",
          borderRadius: "15px",
          width: "200px",
        }}
      >
        Escanea el codigo
      </div>
      <img
        src={company?.qr_code_image_path}
        alt="qrcode"
        className="p-0 m-0"
        style={{
          width: "200px",
          borderRadius: "10px",
          border: `8px solid rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`,
        }}
      />
      <div className="mt-2">
        <span
          className="bold"
          style={{
            color: `rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`,
          }}
        >
          QR
        </span>{" "}
        para registrarse
      </div>
    </>
  );
};

export default QrcodeCompany;
