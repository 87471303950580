const QrcodeDynamic = ({ assets }: any) => {
  return (
    <>
      <div
        className="bold mb-2 py-1 px-3 text-center"
        style={{
          background: "#000",
          color: "#FFF",
          borderRadius: "15px",
          width: "200px",
        }}
      >
        {assets?.events_qrcode_info_1
          ? assets.events_qrcode_info_1
          : "Escanea el codigo"}
      </div>
      <img
        src={assets?.events_qrcode_dynamic_url_image}
        alt="qrcode"
        className="p-0 m-0"
        style={{
          width: "200px",
          borderRadius: "10px",
          border: `8px solid rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`,
        }}
      />
      <div className="mt-2">
        {assets?.events_qrcode_info_2 ? (
          <span className="bold">{assets.events_qrcode_info_2}</span>
        ) : (
          <>
            <span
              className="bold"
              style={{
                color: `rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`,
              }}
            >
              QR
            </span>{" "}
            para registrarse
          </>
        )}
      </div>
    </>
  );
};

export default QrcodeDynamic;
